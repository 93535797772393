<template>
  <div
    class="m-search-bar"
  >
    <input
      ref="searchInput"
      v-model="query"
      class="m-search-bar__input"
      type="search"
      autocomplete="off"
      :placeholder="placeholder"
      :aria-label="placeholder"
      @input="onInput($event)"
      @keyup.enter="onInputEnter($event)"
      @keyup.esc="onInputEscape"
      @click="onInputFocus"
      @focus="onInputFocus"
    >
    <!-- @slot -->
    <slot name="icon">
      <span class="m-search-bar__icon">
        <base-icon icon="search" />
      </span>
    </slot>
    <!-- @slot -->
    <slot name="clear-icon">
      <div
        class="m-search-bar__icon -clear"
        :class="{ '-show': query && !!query.length }"
        @click="onClear"
      >
        <base-icon icon="close" />
      </div>
    </slot>
    <client-only>
      <portal to="search-dropdown">
        <search-dropdown
          :query="query"
          :is-input-focused="isInputFocused"
          :is-body-scrolled="isBodyScrolled"
          :search-page-url="searchPageUrl"
          @click-outside="onClickOusideDropdown"
          @query-changed="onQueryChanged"
        />
      </portal>
    </client-only>
  </div>
</template>

<script>
import SearchDropdown from '@/components/molecules/SearchDropdown'
import BaseIcon from '@/components/atoms/BaseIcon.vue';
import debounce from 'debounce';
export default {
  name: 'SearchBarMain',
  components: {
    BaseIcon,
    SearchDropdown
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    isBodyScrolled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: this.$route.query?.query,
      isInputFocused: false,
      minCharacters: 1,
    }
  },
  computed: {
    searchPageUrl () {
      return this.$store.getters['site/getSiteSettings']?.searchPage?.url;
    },
    culture () {
      return this.$store.getters['site/getCurrentCulture'];
    }
  },
  methods: {
    debounceSearchProducts: debounce(function () {
      if (!this.query || this.query.length <= this.minCharacters) return;
      // Get products
      this.$store.dispatch('search/productSearch/fetchSearchResults', {
        query: this.query,
        culture: this.culture,
        itemsPerPage: 4,
        currentPage: 1,
        facets: '',
        orderBy: '',
        order: '',
        filters: []
      });
    }, 750),
    debounceSearchContent: debounce(function () {
      if (!this.query || this.query.length <= this.minCharacters) return;
      // Get content
      this.$store.dispatch('content/contentSearch/fetchResult', {
        query: this.query,
        template: '', // Empty means return all types
        culture: this.culture,
        itemsPerPage: 12,
        currentPage: 1
      })
    }, 750),
    onClear () {
      this.query = '';
      this.$el.blur();
    },
    async onInput () {
      this.query = event.target.value;
      this.isInputFocused = true;
      this.debounceSearchProducts();
      this.debounceSearchContent();
    },
    onInputEnter (event) {
      event.target.blur();
      this.isInputFocused = false;
      // Go to the search page
      this.$router.push({
        path: this.searchPageUrl,
        query: { query: event.target.value }
      });
    },
    onInputFocus () {
      this.isInputFocused = true;
    },
    onInputEscape () {
      this.isInputFocused = false;
    },
    onQueryChanged (query) {
      this.query = query
      this.isInputFocused = false;
    },
    onClickOusideDropdown (event) {
      if (!event || !this.$el.contains(event.target)) {
        this.isInputFocused = false;
      }
    }
  }
};
</script>
