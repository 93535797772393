<template>
  <div>
    <header
      class="o-header d-flex align-items-center pt-xl-3"
      :class="{ '-has-border': isScrolled, 'border-0': isActive }"
    >
      <div class="container">
        <div class="row d-flex flex-lg-column">
          <div class="col-9 col-lg-7 offset-md-3 col-md-6 d-flex d-lg-none align-items-center justify-content-md-center o-header__container">
            <div
              class="o-header__logo"
              :class="{ '-scrolled': isScrolled }"
            >
              <the-logo />
            </div>
          </div>
          <div class="d-none d-lg-flex align-items-center justify-content-md-center o-header__container">
            <div class="o-header__desktop-logo">
              <the-logo />
            </div>
          </div>
          <div class="col-3 col-lg-12">
            <div class="row justify-content-end">
              <div class="col-3 col-md-3 col-lg-2 d-flex order-lg-2 align-items-start justify-content-end">
                <button class="o-header__icon -search mr-3 mr-lg-5" :class="{ '-show': isScrolled }" @click="openHeader">
                  <base-icon icon="search" />
                </button>
                <base-link
                  v-if="$isNotNullOrUndefined(userPage) && !isLoggedIn"
                  class="o-header__icon -login mr-lg-3"
                  :quick-link="userPage"
                  :link="userPage.link && !userPage.isCheckoutLink ? removeHostFromUrl(userPage.link.url) : null"
                  :target="userPage.link ? userPage.link.target : null"
                >
                  <span class="d-none semibold">{{ userPage.link.name }}</span>
                  <base-icon v-if="$isNotNullOrUndefined(userPage.icon)" :icon="userPage.icon" class="" />
                </base-link>
                <dropdown
                  v-if="isLoggedIn"
                  :show-chevron="false"
                  show-custom-icon
                  :custom-icon="isImpersonating ? 'user-impersonation' : userPage.icon"
                  class="o-header__icon -login mr-lg-3"
                  :class="{ '-primary': isLoggedIn }"
                  @mouseover.native="isHoveringUserIcon = true"
                  @mouseleave.native="isHoveringUserIcon = false"
                >
                  <base-link
                    v-if="$isNotNullOrUndefined(userPage)"
                    class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                    :link="userPage.link && !userPage.isCheckoutLink ? removeHostFromUrl(userPage.link.url) : null"
                    :target="userPage.link ? userPage.link.target : null"
                  >
                    <span class="semibold">{{ userPage.link.name }}</span>
                  </base-link>
                  <template v-for="(userMenuItem, index) in userMenu">
                    <base-link
                      v-if="isAllowedToSeeUserItem(userMenuItem)"
                      :key="index"
                      class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                      :link="removeHostFromUrl(userMenuItem.url)"
                    >
                      <span class="semibold">{{ userMenuItem.meta.title }}</span>
                    </base-link>
                  </template>
                  <base-link
                    class="m-dropdown__option py-2 px-3 text-decoration-none d-flex"
                    @click.native="logout"
                  >
                    <span class="semibold">{{ $t('logOut', 'Log ud') }}</span>
                  </base-link>
                </dropdown>
                <basket-button class="o-header__icon" @click="miniBasketIsVisible = !miniBasketIsVisible" />
                <!--
            <language-selector />
            -->
                <sidebar
                  :visible="miniBasketIsVisible"
                  :title="miniBasketHeadline"
                  :body-scroll="isMobile ? false : true"
                  :is-body-scrolled="isScrolled"
                  @close="miniBasketIsVisible = false"
                >
                  <div
                    v-if="isBasketEmpty"
                    class="pb-8"
                  >
                    {{ $t('EmptyBasket', 'Din kurv er tom. Tilføj produkter fra vores brede sortiment') }}
                  </div>
                  <mini-basket v-if="!isBasketEmpty" :track-basket-views="true" />
                  <template v-slot:content-bottom>
                    <div
                      v-if="!isBasketEmpty"
                      class="px-4 px-md-8 py-5"
                    >
                      <order-summary-punch-out v-if="isPunchOutUser" />
                      <order-summary
                        v-else
                        :summary="basket.orderSummary"
                        :button-text="$t('goToCheckout', 'Gå til kassen')"
                        :button-link="checkoutPage"
                        :orderlines="basket.orderLines"
                      />
                    </div>
                  </template>
                </sidebar>
              </div>
              <div class="d-lg-block  col-lg-10 order-lg-1 align-items-center">
                <the-desktop-navigation v-if="!$ua.isFromCrawler()" />
                <navigation-tree v-else />
              </div>
              <div
                v-if="isHoveringUserIcon && isImpersonating"
                class="impersonation"
              >
                {{ $t('headerImpersonationLoggedInMessage', 'Du er nu logget ind som {customerName}', { customerName }) }}
              </div>
            </div>
          </div>
          <div class="d-flex col-12 order-lg-3 align-items-center">
            <search-bar
              :placeholder="$t('SearchPlaceholder', 'Search for items')"
              :is-body-scrolled="isScrolled"
              class="justify-items-center"
            />
          </div>
        </div>
      </div>
      <portal-target name="megamenu" />
      <portal-target name="search-dropdown" />
    </header>
    <the-mobile-navigation v-if="!$ua.isFromCrawler()" class="d-block d-lg-none" />
  </div>
</template>
<script>
import BaseLink from '@/components/atoms/BaseLink'
import OrderSummary from '@/components/molecules/ShopCommerce/OrderSummary'
import OrderSummaryPunchOut from '@/components/molecules/ShopCommerce/OrderSummaryPunchOut';
import TheDesktopNavigation from '@/components/molecules/TheDesktopNavigation';
import TheMobileNavigation from '@/components/molecules/TheMobileNavigation';
// import LanguageSelector from '@/components/molecules/LanguageSelector';
import BasketButton from '@/components/molecules/BasketButton';
import SearchBar from '@/components/molecules/SearchBarMain';
import BaseIcon from '@/components/atoms/BaseIcon';
import Sidebar from '@/components/organisms/Sidebar';
import MiniBasket from '@/components/organisms/ShopCommerce/MiniBasket';
import LoginMixin from '@/mixins/loginMixin';
import Dropdown from '@/components/molecules/Dropdown';
import TheLogo from '@/components/molecules/TheLogo.vue';
import NavigationTree from '@/components/molecules/NavigationTree';
import {
  mapMobileObserver,
  unMapMobileObserver
} from '@/utils/mobile-observer';

export default {
  name: 'TheHeader',
  components: {
    TheDesktopNavigation,
    TheMobileNavigation,
    // LanguageSelector,
    SearchBar,
    BaseIcon,
    Sidebar,
    BasketButton,
    MiniBasket,
    OrderSummary,
    OrderSummaryPunchOut,
    BaseLink,
    Dropdown,
    TheLogo,
    NavigationTree
  },
  mixins: [LoginMixin],
  async fetch () {
    await this.$store.dispatch('cart/basket/fetchBasket', {
      culture: this.$store.getters['site/getCurrentCulture'],
    });

    if (this.user) {
      await this.$store.dispatch('site/fetchUserMenu');
    }
  },
  data () {
    return {
      isScrolled: false,
      miniBasketIsVisible: false,
      isHoveringUserIcon: false,
      intervalId: null
    };
  },
  computed: {
    ...mapMobileObserver(),
    isActive () {
      return this.$store.getters['navigation/getActiveState'];
    },
    basket () {
      return this.$store.getters['cart/basket/getBasket'];
    },
    searchResultsCount () {
      return this.$store.getters['search/productSearch/getTotalCount'];
    },
    currentSearchQuery () {
     return this.$store.getters['search/productSearch/getCurrentQuery'];
    },
    userPage () {
      const loginLink = this.$store.getters['site/getSiteSettings']?.loginLink;
      const myAccountLink = this.$store.getters['site/getSiteSettings']?.myAccountLink;

      if(this.$isNotNullOrUndefined(loginLink) && this.$isNotNullOrUndefined(myAccountLink)) {
        if(this.isLoggedIn) {
          myAccountLink.name = this.$t('myAccount', 'Min konto');
          return { 'icon': 'user', 'link': myAccountLink };
        }

        loginLink.name = this.$t('login', 'Log ind');
        return { 'icon': 'user', 'link': loginLink };
      } else {
        return null
      }
    },
    checkoutPage () {
      return this.$store.getters['site/getSiteSettings']?.checkoutPage?.url;
    },
    userMenu () {
      return this.$store.state.site.userMenu;
    },
    totalQuantity () {
      return this.$isNotNullOrUndefined(this.basket.orderSummary)
        ? this.basket.orderSummary.totalQuantity
        : 0;
    },
    miniBasketHeadline () {
      return `${this.$t('yourShoppingCart', 'Din indkøbskurv')} (${this.totalQuantity} ${this.goodsTranslation})`;
    },
    goodsTranslation () {
      return this.totalQuantity > 1
        ? this.$t('goodsPlural', 'varer')
        : this.$t('goodsSingular', 'vare');
    },
    isImpersonating () {
      return this.$store.getters['user/user/isImpersonating'];
    },
    isPunchOutUser () {
      return this.$store.getters['user/user/isPunchOutUser'];
    },
    isConsultant () {
      return this.$store.getters['user/user/isConsultant'];
    },
    hasMultipleAccounts () {
      return !!this.user.properties?.customerNumbers;
    },
    isBasketEmpty () {
      return this.$store.getters['cart/basket/isBasketEmpty'];
    },
    hasCustomerNumber () {
      return this.$store.getters['user/user/hasCustomerNumber'];
    },
    hasAgreementNo () {
      return this.user?.accountProperties?.agreementNo;
    },
    customerName () {
      return this.user?.accountProperties?.customerName
    },
    getLoginPageLink () {
      return this.$store.getters['site/getSiteSettings']?.loginLink?.url;
    }
  },
  watch: {
    isLoggedIn: {
      handler: async function (value) {
        if(value) {
          this.intervalId = setInterval(this.checkLoginStatus, 30000);
        }
      }
    }
  },
  created () {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  mounted () {
    if (this.isLoggedIn && !this.intervalId) {
      this.intervalId = setInterval(this.checkLoginStatus, 30000);
    }
  },
  destroyed () {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
    unMapMobileObserver();
  },
  methods: {
    async checkLoginStatus () {
      try {
        await this.$repositories.authentication.isLoggedIn(), Promise.resolve();
      }
      catch {
        this.$store.dispatch('user/user/setUser', '');
        if(this.intervalId) {
          this.$router.push(this.getLoginPageLink);
        }
        this.cleanInterval();
      }
    },
    cleanInterval () {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    handleScroll () {
      this.isScrolled = window.scrollY > 0;
    },
    removeHostFromUrl (url){
      return url.replace('https://' + this.hostName, '')
                      .replace('http://' + this.hostName, '')
    },
    openHeader () {
      if (window.scrollY > 0) {
        this.isScrolled = false;
      }
    },
    logout () {
      this.$store.dispatch('user/user/logout');
      this.cleanInterval();
    },
    isAllowedToSeeUserItem (userMenuItem) {
      if ((userMenuItem?.meta?.template === 'favoritePage' || userMenuItem?.meta?.template === 'latestBoughtProductsPage') && !this.hasCustomerNumber) {
        return false;
      }

      if ((userMenuItem?.meta?.template === 'assortmentsPage') && !this.hasAgreementNo) {
        return false;
      }

      if (userMenuItem?.meta?.template === 'impersonationPage' && !this.isConsultant && !this.hasMultipleAccounts) {
        return false;
      }

      return true;
    },
  }
};
</script>
