export const state = () => ({
  navigationItems: [],
  contentNavigationItems: [],
  shopNavigationItems: [],
  activeLevel: 0,
  activePath: [],
  activeItem: null,
  isActive: false,
  desktopMenuisActive: false,
  openId: ''
});

export const mutations = {
  setContentNavigation (state, data) {
    if(data) {
      state.contentNavigationItems = data;
    }
  },
  setShopNavigation (state, data) {
    state.shopNavigationItems = data;
  },
  setActiveId (state, data) {
    state.openId = data;
  },
  setActiveLevel (state, data) {
    state.activeLevel = data;
  },
  setActiveItem (state, data) {
    state.activeItem = data;
  },
  setActivePath (state, data) {
    if (state.activePath.indexOf(data) === -1) {
      state.activePath.push(data);
    }
  },
  setActiveState (state, data) {
    state.isActive = data;
  },
  reduceActivePath (state) {
    state.activePath.splice(-1, 1);
  },
  setDesktopActiveState (state, data) {
    state.desktopMenuisActive = data;
  },
  resetActiveItems (state) {
    state.activeItem = null
    state.activePath = []
    state.activeLevel = 0
  }
};

export const actions = {
  async fetchNavigation ({ commit }, { req }) {
    const url = decodeURIComponent(req.url);
    try {
      const {data} = await this.$repositories.navigation.byUrl(url);
      commit('setContentNavigation', data.result);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchShopNavigation ({ commit }, { req }) {
    const url = decodeURIComponent(req.url);
    try {
      const {data} = await this.$repositories.navigation.byType(url, 'shop');
      if (typeof data === 'object') {
        commit('setShopNavigation', data);
      }
    } catch ( error ) {
      console.log(error)
    }
  }
};

export const getters = {
  getContentNavigation (state) {
    return state.contentNavigationItems;
  },
  getNavigationItems (state) {
    // TODO: The doctype value will be changed in the endpoint from
    // 'type' to 'template' - we are just checking both right now,
    // but eventually we can just check item.template

    // Embed categories into the content navigation
    let categories = state.contentNavigationItems?.children?.find(item => item.type === 'categories' || item.template === 'categories');
    let shopNavigation = categories ? state.contentNavigationItems?.children?.map(item => {
      if (categories && item?.id === categories?.id) {
        item.children = state.shopNavigationItems;
        item.hasChildren = !!state.shopNavigationItems.length;
      }
      return item
    }) : state.contentNavigationItems?.children;
    return shopNavigation;
  },
  getActiveId (state) {
    return state.openId;
  },
  getActiveLevel (state) {
    return state.activeLevel;
  },
  getActivePath (state) {
    return state.activePath;
  },
  getActiveState (state) {
    return state.isActive;
  },
  getDesktopActiveState (state) {
    return state.desktopMenuisActive;
  },
  getActiveItem (state) {
    return state.activeItem;
  }
};
