<template>
  <div>
    <sidebar
      class="-mobile-navigation"
      :visible="menuIsOpen"
      @close="menuIsOpen = false"
    >
      <template v-slot:bar>
        <div class="m-mobile-navigation__header" />
      </template>
      <nav
        v-if="menuIsOpen"
        ref="content"
        class="m-mobile-navigation"
        :class="{ '-open': menuIsOpen }"
      >
        <div
          v-if="navigationItems"
          class="m-mobile-navigation__panel"
          :style="{ transform: 'translateX(' + transform + '%)' }"
        >
          <mobile-navigation-item
            v-for="(item, i) in navigationItems"
            :key="i"
            :item="item"
          />
        </div>
      </nav>
    </sidebar>
    <mobile-navigation-button
      :is-active="menuIsOpen"
      @toggleMenu="toggleMenu"
    />
  </div>
</template>

<script>
import MobileNavigationButton from '@/components/molecules/MobileNavigationButton.vue';
import MobileNavigationItem from '@/components/molecules/MobileNavigationItem.vue';
import { mapMutations } from 'vuex';
import Sidebar from '@/components/organisms/Sidebar.vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  name: 'TheMobileNavigation',
  components: {
    MobileNavigationButton,
    MobileNavigationItem,
    Sidebar
  },
  props: {},
  data () {
    return {
      menuIsOpen: false,
      transform: 0
    };
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems']?.filter(x => x && x?.link?.name && !x.hideInNavigation);
    },
    activeLevel () {
      return this.$store.getters['navigation/getActiveLevel'];
    },
    activePath () {
      return this.$store.getters['navigation/getActivePath'];
    },
    currentPath () {
      return this.$route.path
    }
  },
  watch: {
    activeLevel () {
      this.transform = -100 * this.activeLevel;
    },
    $route (to, from) {
      if (this.menuIsOpen) {
        this.toggleMenu();
      }
      if (this.currentPath.length > 0) {
        this.setMenuActivePath();
      }
      if(to, from) {
        document.body.classList.remove('-scroll-locked');
        clearAllBodyScrollLocks();
      }
    },
    menuIsOpen: {
      handler: function (value) {
        if (typeof window === 'undefined' || typeof document === 'undefined')
          return;
        if (value) {
          this.$nextTick(() => {

            document.body.classList.add('-scroll-locked');
            disableBodyScroll(this.$refs.content);
          });
        } else {
          document.body.classList.remove('-scroll-locked');
          clearAllBodyScrollLocks();
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    if (this.currentPath.length > 0) {
      this.setMenuActivePath();
    }
  },
  methods: {
    ...mapMutations('navigation', [
      'setActiveLevel',
      'setActivePath',
      'setActiveItem',
      'reduceActivePath',
      'setActiveState',
      'resetActiveItems'
    ]),
    setMenuActivePath () {
      // Reset menu
      this.resetActiveItems();
      this.verifyItemPath(this.navigationItems, this.currentPath, 1);
    },
    verifyItemPath (items, curPath) {
      items?.forEach(item => {
        const itemPath = item.link.url;

        if (curPath.includes(itemPath)) {
          this.setActive(item);
          if (item.hasChildren && item.children.some(childItem => !childItem.hideInNavigation)) {
            this.verifyItemPath(item.children, curPath);
          }
        }
      });
    },
    setActive (item) {
      if (item.hasChildren) {
        if(item.type=='ucommerceCategoryPage') {
          this.setActiveLevel(item.level + 2);
        } else {
          if (item.children.every(childItem => childItem.hideInNavigation)) {
            this.setActiveLevel(item.level - 1);
          } else {
            this.setActiveLevel(item.level);
          }
        }
        this.setActivePath(item.id);
        this.setActiveItem(item);
      }
    },
    goBack () {
      this.setActiveLevel(this.activeLevel - 1);
      this.reduceActivePath();
    },
    toggleMenu () {
      this.menuIsOpen = !this.menuIsOpen;
      this.setActiveState(this.menuIsOpen);
    }
  }
};
</script>
