<template>
  <div class="m-mega-menu-column d-flex flex-column">
    <slot
      name="title"
      v-bind="{ title }"
    >
      <p v-if="title" class="m-mega-menu-column__title h3">
        {{ title }}
      </p>
    </slot>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'MegaMenuColumn',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
