<template>
  <base-button
    class="m-mobile-navigation-button shadow"
    :class="{ '-active': isActive }"
    @click="toggleMenu"
  >
    <svg
      class="m-mobile-navigation-button__svg"
      viewBox="0 0 800 600"
    >
      <path
        id="top"
        class="m-mobile-navigation-button__path -top"
        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
      />
      <path
        id="middle"
        class="m-mobile-navigation-button__path -middle"
        d="M300,320 L540,320"
      />
      <path
        id="bottom"
        class="m-mobile-navigation-button__path -bottom"
        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
      />
    </svg>
  </base-button>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';

export default {
  name: 'MobileNavigationButton',
  components: {
    BaseButton
  },
  props: {
    isActive: {
      requirred: true,
      default: false,
      type: Boolean
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu');
    }
  }
};
</script>
