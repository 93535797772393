<template>
  <div
    v-click-outside="closeHandler"
    class="m-search-dropdown px-3 px-lg-5"
    :class="{ '-has-scroll': isBodyScrolled, '-open': isOpen }"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-3 d-flex flex-wrap align-content-between">
          <div class="m-search-dropdown__section">
            <div class="m-search-dropdown__headline">
              {{ $t('SearchSuggestions', 'Søgeforslag') }}
            </div>
            <ul v-if="suggestions && suggestions.results && suggestions.results.length" class="list-unstyled">
              <li
                v-for="(suggestion, i) in suggestions.results"
                :key="i"
                :class="{ 'd-none d-lg-block': i >= 4 }"
              >
                <a :href="`${searchPageUrl}?query=${suggestion}`" @click.prevent="onSetUrlQuery(suggestion)">
                  <span v-if="suggestion.includes(query.toLowerCase())" v-html="formatSuggestion(suggestion, query)" />
                  <span v-else>{{ suggestion }}</span>
                </a>
              </li>
            </ul>
            <p v-else>
              {{ $t('SearchNoSuggestions', 'Ingen søgeforslag.') }}
            </p>
          </div>
          <div class="m-search-dropdown__section -articles">
            <div class="m-search-dropdown__headline">
              {{ $t('Content', 'Indhold') }} ({{ contentResults.items ? contentResults.items.length : 0 }})
            </div>
            <spot-page-summary
              v-if="contentResults.items && contentResults.items.length"
              :link="contentResults.items[0].link"
              :headline="contentResults.items[0].headline"
              :text="contentResults.items[0].subline"
              :image="contentResults.items[0].image"
              @clicked="closeHandler"
            />
            <p v-else>
              {{ $t('SearchNoArticles', 'Ingen resultater.') }}
            </p>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="m-search-dropdown__section -products">
            <div class="m-search-dropdown__headline">
              {{ $t('Products', 'Produkter') }} <span v-if="!isProductsLoading && productResults.totalItems">({{ productResults.totalItems }})</span>
            </div>
            <div v-if="isProductsLoading" class="row">
              <div v-for="(n, i) in 4" :key="i" class="col-sm-6 col-md-4 col-xl-3">
                <skeleton-loader
                  layout="card"
                  :aspect-ratio="238/414"
                  :class="getProductClasses(i)"
                />
              </div>
            </div>
            <div
              v-else-if="productResults.totalItems"
              class="row mb-8"
            >
              <div
                v-for="(product, i) in productResults.items.slice(0, 4)"
                :key="i"
                class="col-sm-6 col-md-4 col-xl-3"
                :class="getProductClasses(i)"
              >
                <product-card
                  :is-search-result="true"
                  :product="product"
                  show-add-to-cart-button
                  :is-recommended="true"
                  @click-card="closeHandler"
                />
              </div>
            </div>
            <p v-else-if="productResultsTotal >= 0" class="mb-5">
              {{ $t('SearchNoProductsFound', 'Ingen produkter fundet.') }}
            </p>
            <div class="text-right">
              <base-button class="m-search-dropdown__button" @click.prevent="onSetUrlQuery(query)">
                {{ $t('ShowAllResults', 'Vis alle resultater') }} ({{ total }})
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/atoms/SkeletonLoader'
import ProductCard from '@/components/organisms/ShopCommerce/ProductCard'
import BaseButton from '@/components/atoms/BaseButton'
import SpotPageSummary from '@/components/molecules/SpotPageSummary'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { clickOutside } from '@/utils/click-outside-directive';
import { getSpotsByTemplate } from '@/utils/spots'

export default {
  name: 'SearchDropdown',
  components: {
    ProductCard,
    SkeletonLoader,
    BaseButton,
    SpotPageSummary
  },
  directives: {
    clickOutside,
  },
  props: {
    query: {
      type: String,
      default: ''
    },
    searchPageUrl: {
      type: String,
      default: ''
    },
    isBodyScrolled: {
      type: Boolean,
      default: false
    },
    isInputFocused: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      timer: null,
      minCharacters: 1,
      suggestions: null
    }
  },
  computed: {
    isOpen () {
      return this.isInputFocused && this.query.length > this.minCharacters
    },
    productResults () {
      return this.$store.getters['search/productSearch/getProducts'];
    },
    productResultsTotal () {
      return this.productResults?.totalItems ? this.productResults.totalItems : 0;
    },
    contentResults () {
      return this.getSpotsByTemplate(this.$store.getters['content/contentSearch/getResult'], ['project', 'recipe', 'event', 'catalogPage']);
    },
    total () {
      return (this.contentResults?.items?.length ? this.contentResults.items.length : 0) + this.productResultsTotal;
    },
    isProductsLoading () {
      return this.$store.getters['search/productSearch/isLoading'];
    },
    culture () {
      return this.$store.getters['site/getCurrentCulture'];
    }
  },
  watch: {
    query: {
      handler: function (queryString) {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          if (queryString.length <= 0) return;
          // Get suggestions
          this.suggestions = await this.$repositories.suggestions.byQuery(queryString, this.culture, 8);
        }, 750);
      }
    },
    isOpen (open) {
      if (open) {
        this.$nextTick(() => {
          disableBodyScroll(this.$refs.content);
        });
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
  methods: {
    getSpotsByTemplate,
    getProductClasses (index) {
      return {
        'd-none': index > 0,
        'd-sm-block': index === 1,
        'd-md-block': index === 2,
        'd-xl-block': index >= 3
      }
    },
    closeHandler (event) {
      this.$emit('click-outside', event);
    },
    onSetUrlQuery (queryString) {
      this.$emit('query-changed', queryString);
      this.$router.push({
        path: this.searchPageUrl,
        query: {
          query: queryString
        }
      })
    },
    formatSuggestion (result, query) {
      return result.replace(query, `<b>${query}</b>`)


    }
  }
}
</script>
