<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="o-mega-menu py-8"
      @mouseleave="closeMenu"
    >
      <div class="container">
        <div class="o-mega-menu__content">
          <div
            class="o-mega-menu__menu"
            :class="{ '-has-aside': this.$slots.aside }"
          >
            <mega-menu-column :class="{'-active' : columnIsactive(items)}">
              <base-link
                v-for="(item, i) in items.filter(x => !x.hideInNavigation)"
                :key="i"
                :link="item.link.url"
                class="m-mega-menu-column__menu-item text-decoration-none py-2"
                :class="{'-active' : itemIsActive(item)}"
                @mouseover.native="showChildren(item)"
                @touchstart.native="touchVerifyLink(item)"
                @click.native="closeMenu"
              >
                {{ item.link.name }}
                <base-icon
                  v-if="itemIsActive(item)"
                  icon="arrow-right"
                  class="-icon -right d-inline"
                />
              </base-link>
            </mega-menu-column>

            <mega-menu-column
              v-for="(item, i1) in visibleColumns"
              :key="i1"
              :class="{'-active' : Array.isArray(categories) && categories.length > 0 }"
            >
              <base-link
                v-for="(subItem, i2) in item.children.filter(x => !x.hideInNavigation).slice(0, maxVisibleCategories)"
                :key="i2"
                :link="subItem.link.url"
                class="m-mega-menu-column__menu-item text-decoration-none py-2"
                :class="{'-active' : itemIsActive(subItem)}"
                @mouseover.native="showChildren(subItem)"
                @touchstart.native="touchVerifyLink(subItem)"
                @click.native="closeMenu"
              >
                {{ subItem.link.name }}
                <base-icon
                  v-if="itemIsActive(subItem)"
                  icon="arrow-right"
                  class="-icon -right d-inline"
                />
              </base-link>
              <base-link
                v-if="item.children.length > maxVisibleCategories"
                :link="item.link.url"
                class="m-mega-menu-column__more"
                @touchstart.native="touchVerifyLink(item)"
                @click.native="closeMenu"
              >
                {{ $t('showAll', 'Vis alle') }}
                <base-icon
                  icon="arrow-right"
                  class="-icon -right d-inline"
                />
              </base-link>
            </mega-menu-column>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink.vue';
import BaseIcon from '@/components/atoms/BaseIcon';
import MegaMenuColumn from '@/components/molecules/MegaMenuColumn.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'TheMegaMenu',
  components: {
    BaseLink,
    MegaMenuColumn,
    BaseIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default () {
        return [];
      },
      required: true
    }
  },
  data () {
    return {
      openedItems: [],
      categories: [],
      productIds: [],
      maxLevels: 1,
      isLoading: false,
      maxVisibleCategories: 10,
      currentLink: {}
    }
  },
  computed: {
    visibleColumns () {
      return this.openedItems.filter(x => x.level <= this.maxLevels)
    }
  },
  methods: {
    ...mapMutations('navigation', [
      'setDesktopActiveState'
    ]),
    hasSubItems (payload){
      return (this.$isNotNullOrUndefined(payload.children) && payload.children.length > 0)
    },
    itemIsActive (payload) {
      return this.openedItems.includes(payload)
    },
    columnIsactive (payload) {
      return this.openedItems.some(x => payload.includes(x));
    },
    touchVerifyLink (payload) {
      if(!this.hasSubItems(payload)) {
        this.touchOpenLink(payload);
      }
    },
    touchOpenLink (payload) {
      this.$router.push(payload.link.url);
      this.closeMenu();
    },
    closeMenu (){
      this.setDesktopActiveState(false);
      this.openedItems = [];
      this.productIds = [];
      this.products = [];
      this.categories = [];
      this.currentLink = {};
    },
    showChildren (payload) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if(this.hasSubItems(payload)) {
          if(payload.level == 0){
            this.openedItems = [];
            this.categories = [];
          }
          if (this.openedItems.length > 1) {
            this.openedItems.pop()
            this.categories = [];
          }

          this.openedItems.push(payload);
          this.currentLink = payload;
          if(payload.level == 1){
            this.categories = payload?.children;
          }
        } else if (!this.hasSubItems(payload) && payload.level == 1) {
          if (this.openedItems.length > 1) {
            this.openedItems.pop()
            this.categories = [];
          }
        }
      }, 50);
    }
  }
};
</script>
