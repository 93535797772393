<template>
  <nav class="m-desktop-navigation">
    <div class="m-desktop-navigation__container d-flex flex-column align-items-center ml-xl-4">
      <div class="m-desktop-navigation__nav offset-xl-1 justify-content-center">
        <desktop-navigation-item
          v-if="navigationItems"
          :items="navigationItems"
          :open="open"
          :active-state="activeState"
        />
      </div>
    </div>
    <portal
      to="megamenu"
    >
      <div
        v-for="(item, i) in navigationItems"
        :key="i"
      >
        <mega-menu
          v-if="item && item.hasChildren"
          :items="item.children"
          :visible="open === item.id && activeState"
        >
          <template v-slot:aside>
            <div class="row no-gutters">
              <div class="col-5" />
            </div>
          </template>
        </mega-menu>
      </div>
    </portal>
  </nav>
</template>

<script>
import MegaMenu from '@/components/organisms/MegaMenu.vue';
import DesktopNavigationItem from '@/components/molecules/TheDesktopNavigationItem.vue';
/* import TheLogo from '@/components/molecules/TheLogo.vue'; */

export default {
  name: 'TheNavigation',
  components: {
    MegaMenu,
    /* TheLogo, */
    DesktopNavigationItem,
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems']?.filter(x => x && x?.link?.name && !x.hideInNavigation);
    },
    sliceBefore () {
      return Math.round(this.navigationItems.length / 2);
    },
    sliceAfter () {
      return this.navigationItems.length - Math.floor(this.navigationItems.length / 2);
    },
    activeState () {
       return this.$store.getters['navigation/getDesktopActiveState'];
    },
    open () {
      return this.$store.getters['navigation/getActiveId'];
    },
  },
};
</script>
